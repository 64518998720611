import { Col, Modal, Row } from "react-bootstrap";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import floatSuccess from "images/svg/float/float-success.svg";
import { toast } from "react-toastify";
import WarningModal from "components/Modal/WarningModal";
import ConfirmAction from "components/Modal/ConfirmAction";
import { FloatService } from "services/float.service";
import { Tooltip } from 'react-tooltip';
import { PieChart, Pie, Cell } from 'recharts';
import { useSelector, useDispatch } from "react-redux";
import { currencyFormatter, dateFormatter, handleRequestErrors, timeFormatter } from "utils/helpers";
import clsx from "clsx";
import tv_W from "images/svg/income/table/tv.svg";
import patchPen from "images/svg/float/patch-pen.svg";
import patchStatus from "images/svg/float/patch-stat.svg";
import more from "images/more.png";
import { miscDetails } from "redux/miscellaneous";
import { setWallet } from "redux/wallet";
import { WalletService } from "services/wallet.service";


function FloatOverview () {
    const [data, setData] = useState({
        isLoading: true,
        items: []
    });
    const [show, setShow] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false);
    const [checkedValue, setCheckedValue] = useState('next')
    const [warningType, setWarningType] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [stats, setStats] = useState({});
    const [disableAction, setDisableAction] = useState(false);
    const [showLowBalance, setShowLowBalance] = useState(false);
    const [index, setIndex] = useState(null);
    let navigate = useNavigate();
    const b2b_data = useSelector(state => state?.loginData?.data);
    const dispatch = useDispatch();
    const wallet = useSelector(state => state?.walletData?.wallet);

    const [progressData, setProgressData] = useState([]);

    const repaymentHistory = async() => {
        await FloatService.repaymentHistory(b2b_data?.user?.businessId)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
              setData({
                  ...data,
                  isLoading: false,
                  items: res.data.reverse()
              })
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err)
        });
    }

    const [pendingFloat, setPendingFloat] = useState(null);

    const checkPendingFloat = async() => {
        await FloatService.checkPendingFloat(b2b_data?.user?.businessId)
        .then((response) => response.data)
        .then((res) => {
            console.log(res.data)
            if (res.status === 'success') {
              setPendingFloat(res.data)
              const percentage1 = res?.data?.data?.businessBankDetail ? 10:0;
              const percentage2 = res?.data?.data?.pdfStatement ? 10:0;
              const percentage3 = res?.data?.data?.director1 ? 25:0;
              const percentage4 = res?.data?.data?.director2 ? 25:0;
              const sum = percentage1+percentage2+percentage3+percentage4;
              setProgressData([
                {data:'done', value: sum},
                {data: 'notdone', value: 100-sum}
              ])
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err)
        });
    }

    useEffect(() => {
        repaymentHistory()
        checkPendingFloat()
        callWallet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        sessionStorage.removeItem( "floatRequest")  
        FloatService.repaymentStats(b2b_data?.user?.businessId)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                setStats(res.data)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err)
        });
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    const callWallet = () => {
        WalletService.getWallet()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true && res.data !== null ){
                    dispatch(setWallet(res.data))
                }else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {});
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const initiateRequest = (amountType) => {
        let payload = {
            businessId: b2b_data?.user?.businessId
        }
        FloatService.floatInitiateRequest(payload)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                sessionStorage.setItem('floatId', JSON.stringify(res.data._id))
                navigate(`/float/new-request?amountType=${amountType}`)
            } else {
                toast.error(res.message)
            }
            setDisableAction(false)
        })
        .catch((err) => {
            handleRequestErrors(err)
            setDisableAction(false)
        });
    }

    const viewRequest = (id) => {
    FloatService.repaymentHistoryById(id)
        .then(response=> response.data)
        .then(res => {
            if(res.status === 'success'){
                sessionStorage.setItem('floatRepayment', JSON.stringify(res.data))
                navigate('/float/repayment-schedule')
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err, true)
        });  
    }

    const handleSubmit = (e) => {
        let payload = {
            floatId: stats?.floatId,
            type: checkedValue
        }
        FloatService.floatBilling(payload)
        .then(response=> response.data)
        .then(res => {
            if(res.success === true){
                setShowSuccess(true)
                setShowWarningModal(false)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err)
        });  
    }

    const checkBalance = (amountType) => {
        if (wallet.ngnBalance>9999 || pendingFloat?.pendingFloat){
            initiateRequest(amountType)
        } else {
            setShowLowBalance(true)
        }
    }

    const colors=['#F81F11','#ADB2B969']

    return(
        <div>
            <h3 className="font-medium text-[1.3em] mb-2">{b2b_data?.businessName}</h3>
            <p className="font-medium mb-2">Overview</p>
            <Row>
                <Col xl={3} lg={4} md={6} >
                    <div className="float_overview-box">
                        <div className="flex justify-between">
                            <div>
                                <p className="text1">Total Request Sent</p>
                                <h4 className="font-medium text-[1.3em] my-2">{stats?.totalRequest}</h4>
                            </div>
                            {
                                pendingFloat?.pendingFloat &&
                                <div 
                                    className="relative cursor-pointer z-[1]"
                                >
                                    <PieChart width={44} height={44}>
                                        <Pie
                                            data={progressData}
                                            innerRadius={14}
                                            outerRadius={22}
                                            paddingAngle={0}
                                            dataKey="value"
                                        >
                                            {data?.items?.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </div>
                            }
                            {(pendingFloat?.pendingFloat&&!showLowBalance) && <p className="absolute right-[28px] top-[45px] text-xs font-semibold mx-auto z-[9999] -mt-[10px]">{progressData?.[0]?.value}%</p>}
                        </div>
                        {
                            pendingFloat?.pendingFloat ?
                            <button 
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Complete your pending Float Request"
                                onClick={()=>checkBalance("naira")} 
                                className="bg-cc-green-3"
                            >
                                Pending Float Request
                            </button>
                            :
                            // <button onClick={()=>setShowRequestOptions(!showRequestOptions)}>Request New Float</button>
                            <button
                                onClick={()=>checkBalance("naira")} 
                                className="bg-cc-blue-6"
                            >
                                Request New Float
                            </button>
                        }
                        {
                            // showRequestOptions &&
                            false &&
                            <div className="float_overview-box-dropdown"> 
                                <button className="mr-2 cursor-pointer" onClick={()=>checkBalance("naira")}>Naira </button>
                                <button onClick={()=>checkBalance("usd")}> USD </button>
                            </div>
                        }
                        <Tooltip id="my-tooltip" place="bottom"/>
                    </div>
                </Col>
                <Col xl={3} lg={4} md={6} >
                    <div className="float_overview-box">
                        <p className="text1">Total Outstanding</p>
                        <h4 className="font-medium text-[1.3em] my-2">{currencyFormatter(stats?.totalOutstanding ? stats?.totalOutstanding : 0)}</h4>
                    </div>
                </Col>
                <Col xl={3} lg={4} md={6} >
                    <div className="float_overview-box">
                        <p className="text1">Total Overdue</p>
                        <h4 className="font-medium text-[1.3em] my-2">{currencyFormatter(stats?.totalDue ? stats?.totalDue : 0 )}</h4>
                    </div>
                </Col>
            </Row>
            <Table
                noTitleIcon={true}
                tableTitle={"Float Repayment History"}
                data={data.items}
                hideSearch={true}
                loading={data.isLoading}
                tableClassName="2xl:min-w-[1200px]"
                rows={[
                    {
                        header:'Request ID',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px] lowercase">{item?.floatId?.requestCode ?? "-"}</span>
                        ),
                    },
                    {
                        header:'Date',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item?.createdAt, 'dd/mm/yyyy')} - {timeFormatter(item?.createdAt)}</span>
                        ),
                    },
                    {
                        header:'Amount',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px]">{currencyFormatter(item?.loanAmount)}</span>
                        ),
                    },
                    {
                        header:'Tenure',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px]">{item?.floatId?.approvedFloat?.loanTenure } months</span>
                        ),
                    },
                    {
                        header:'Interest',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px]">{item?.floatId?.approvedFloat?.monthlyIntrestRate}%</span>
                        ),
                    },
                    {
                        header:'Account Status',
                        view: (item) => (
                            <div className="py-[10px] pl-[10px] flex">
                                <div 
                                    className={clsx(
                                        "flex items-center px-2 rounded-2xl cursor-pointer",
                                        item?.accountStatus ==="open" ? "bg-cc-yellow-4":"bg-[#47D8004F]",
                                    )}
                                >
                                    <div
                                        className={clsx(
                                            "w-1.5 h-1.5 rounded-full mr-1",
                                            item?.accountStatus ==="open" ? "bg-cc-yellow-3":"bg-cc-green-11",
                                        )}
                                    ></div>
                                    <span 
                                        className={clsx(
                                            "text-sm whitespace-nowrap",
                                            item?.accountStatus ==="open" ? "text-cc-yellow-3":"text-cc-green-11",
                                        )}
                                    >
                                        {item?.accountStatus}
                                    </span>
                                </div>
                            </div>
                        ),
                    },
                    {
                        header:'Performance Status',
                        view: (item) => (
                            <div className="py-[10px] pl-[10px] flex">
                                <div 
                                    className={clsx(
                                        "flex items-center px-2 rounded-2xl cursor-pointer",
                                        item?.accountStatus==="closed" ? "bg-[#47D8004F]"
                                        : item?.performanceStatus==="performing" ? "bg-cc-yellow-4"
                                        : "bg-cc-red-6"
                                    )}
                                >
                                    <div
                                        className={clsx(
                                            "w-1.5 h-1.5 rounded-full mr-1",
                                            item?.accountStatus==="closed" ? "bg-cc-green-11"
                                            : item?.performanceStatus==="performing" ? "bg-cc-yellow-3"
                                            : "bg-cc-red-11"
                                        )}
                                    ></div>
                                    <span 
                                        className={clsx(
                                            "text-sm whitespace-nowrap",
                                            item?.accountStatus==="closed" ? "text-cc-green-11"
                                            : item?.performanceStatus==="performing" ? "text-cc-yellow-3"
                                            : "text-cc-red-11"
                                        )}
                                    >
                                        {item.performanceStatus}
                                    </span>
                                </div>
                            </div>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <div className="relative h-[30px] w-[40px] ml-3">
                                <div 
                                    className="rounded-full m-3 w-[25px] h-[25px] cursor-pointer overflow-hidden bg-cc-primary block m-auto"
                                    onClick={()=>index===null?setIndex(item._id):setIndex(null)} 
                                >
                                    <img src={more} alt="" className="absolute top-[10px] left-[11px]"/>
                                </div>
                                {
                                    item._id===index &&
                                    <div className="absolute top-[28px] right-[5px] bg-white rounded-[5px] shadow-md w-[140px] z-[2] p-2.5">
                                        <button 
                                            className="flex items-center text-sm mb-2.5 text-white bg-cc-primary py-1.5 px-2.5 w-[130px] rounded-[6px]"
                                            onClick={()=> viewRequest(item?._id, item?.isApproved)}
                                        >
                                            <img src={tv_W} alt="" className="w-[15px] mr-2" />
                                            <span>View</span>
                                        </button>
                                        <button 
                                            className="flex items-center text-sm mb-2.5 text-white bg-cc-red-2 py-1.5 px-2.5 w-[130px] rounded-[6px]"
                                            onClick={()=>{
                                                dispatch(miscDetails(item))
                                                navigate(`/float/${item.floatId._id}/patch`)
                                            }}
                                        >
                                            <img src={patchPen} alt="" className="w-[15px] mr-2" />
                                            <span>Patch</span>
                                        </button>
                                        <button 
                                            className={clsx(
                                                "flex items-center text-sm text-white py-1.5 px-2.5 w-[130px] rounded-[6px] bg-cc-yellow-1",
                                            )}
                                            onClick={()=>navigate(`/float/patch-status/${item.floatId._id}`)}
                                        >
                                            <img src={patchStatus} alt="" className="w-[15px] mr-2" />
                                            <span>Patch Status</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        ),
                    },
                ]}
            />
            {/* <div>
                <div className="table_top">
                    <div className="home_main-topText-toggles">
                        <div>
                            <h4>Float Repayment History</h4>
                        </div>
                        <div className="home_main-topText-filter flex items-center" style={{marginLeft:'auto'}} onClick={() => {setShowFilter(true) }}>
                            <img src={filter} alt="" /><p>{filterTerm==='all'?'Filter':filterTerm==='seven_days'?'Last 7 days':filterTerm==='last_month'?'Last month':filterTerm==='last_three_months'?'Last 3 months': 'Custom'}</p>
                            {
                                showFilter &&
                                <div className="home_main-topText-filter-options" style={{top:'65px', right:'10px'}}>
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                            setShowFilter(false)
                                        }}
                                    >
                                        <p onClick={()=>{setFilterTerm("seven_days")}}>Last 7 days</p>
                                        <hr/>
                                        <p onClick={()=>{setFilterTerm("last_month")}}>Last Month</p>
                                        <hr/>
                                        <p onClick={()=>{setFilterTerm("last_three_months")}}>Last 3 months</p>
                                    </OutsideClickHandler>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div> */}
            <WarningModal 
                show={showWarningModal} 
                type={warningType} 
                handlePayment={handleSubmit}
                amount={checkedValue === 'next'?stats?.nextRepaymentAmount:stats?.totalOutstanding}  
                handleClose={()=>setShowWarningModal(false)} 
            />
            <Modal show={show} onHide={()=>setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <h5 className="font-medium text-[1.3em]">Float Repayment</h5>
                    <div className="float_modal-options">
                        <div className='radio'>
                            <p onClick={()=> setCheckedValue('next')}>
                                <input type="radio" value='next' checked={checkedValue === 'next'} />
                                <label>Next Repayment</label>
                            </p>
                            <h5 className="font-medium text-[1.3em] mt-1">{currencyFormatter(stats?.nextRepaymentAmount ? stats?.nextRepaymentAmount : 0)}</h5>
                            <p onClick={()=> setCheckedValue('all')}>
                                <input type="radio" value='all' checked={checkedValue === 'all'} />
                                <label>Full Repayment</label>
                            </p>
                            <h5 className="font-medium text-[1.3em] mt-1">{currencyFormatter(stats?.totalOutstanding ? stats?.totalOutstanding : 0)}</h5>
                        </div>
                    </div>
                    <button className="float_modal-action" onClick={()=>{setWarningType('make-repayment'); setShowWarningModal(true); setShow(false)}}>Make Repayment</button>
                </div>
            </Modal>
            <Modal show={showSuccess} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <img src={floatSuccess} alt='icon' />
                    <h5 className="font-medium text-[1.3em] my-2">Payment Succesful</h5>
                    <p style={{color:"#667085"}} className="mb-2">Your Float repayment is succesful</p>
                    <button onClick={()=> window.location.reload()} className="float_modal-action">Go Back</button>
                </div>
            </Modal>
            <ConfirmAction
                show={showLowBalance}
                onCancel={()=>setShowLowBalance(false)}
                onAction={()=>{
                    setDisableAction(true);
                    navigate('/home/wallet');
                }}
                loading={disableAction}
                title={`Float Request`}
                content={
                    <>
                        <p>{`You don't have enough balance to proceed. You need to have a balance of at least ${currencyFormatter(10000)} to complete a new float request process.`}</p>
                        <p className="font-semibold">Current Balance: {currencyFormatter(wallet?.ngnBalance)}<span className="font-normal"></span></p>
                    </>
                }
            />
        </div>
    )
}

export default FloatOverview;