import { useState } from "react";
import {Link} from "react-router-dom";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";

import eye from "images/svg/overview/eye.svg";
import trans from "images/svg/overview/transaction.svg";
import inv from "images/svg/overview/invoice.svg";
import add from "images/svg/home/add-funds.svg";
import more from "images/more.png";
import { currencyFormatter } from "utils/helpers";
import clsx from "clsx";


export default function WalletBox ({
    hideActions=false, 
    hideButton=false
}) {
    const [showMore, setShowMore] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const wallet = useSelector(state => state?.walletData?.wallet);

    return (
        <div className="border-[1px] shadow-md border-cc-primary p-[20px] rounded-[10px] text-white bg-cc-primary">
            <div
                className={clsx(
                    "relative",
                    hideActions && "hidden"
                )}
            >
                <div className="cursor-pointer" onClick={()=>setShowMore(!showMore)}>
                    <img src={more} alt="" className="block mt-2.5 mb-[20px] ml-auto rotate-90"  />
                </div>
                {
                    showMore &&
                    <div className="bg-white absolute right-[18px] rounded-[10px] p-2.5 -top-2.5 flex flex-col gap-1.5">
                        <Link to="/home/wallet" className="link-decor" 
                            onClick={()=> Mixpanel.track('View Account details button was clicked', {
                                    name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                    bussinessName: b2b_data?.businessName
                                })} 
                            >
                                <div className="flex items-center gap-x-1">
                                    <img src={eye} alt="" className="w-[15px]"/>
                                    <p className="m-0 text-[0.85em] text-[#5C5C5C] text-left cursor-pointer">
                                        View Account Details
                                    </p>
                                </div>
                        </Link>
                        <Link to="/home/wallet/transactions" className="link-decor" onClick={()=> Mixpanel.track('View Transactions button was clicked', {
                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                bussinessName: b2b_data?.businessName
                            })}
                        >
                            <div className="flex items-center gap-x-1">
                                <img src={trans} alt="" className="w-[15px]"/>
                                <p className="m-0 text-[0.85em] text-[#5C5C5C] text-left cursor-pointer">
                                    View Transactions
                                </p>
                            </div>
                        </Link>
                        <Link to="/home/wallet/invoices" className="link-decor" onClick={()=> Mixpanel.track('View Invoice button was clicked', {
                            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                            bussinessName: b2b_data?.businessName
                        })}>
                            <div className="flex items-center gap-x-1">
                                <img src={inv} alt="" className="w-[15px]"/>
                                <p className="m-0 text-[0.85em] text-[#5C5C5C] text-left cursor-pointer">
                                    View Invoices
                                </p>
                            </div>
                        </Link>
                    </div>
                }
            </div>
            <div 
                className={clsx(
                    "flex justify-between items-end gap-x-10 gap-y-6 -mt-4 mb-3 sm:flex-col sm:items-start",
                    hideActions && "mt-[20px]"
                )}
            >
                <div>
                    <p className="m-0 text-base">Wallet Balance</p>
                    <h5 className="text-[32px] font-bold">{currencyFormatter(wallet.ngnBalance||0)}</h5>
                </div>
                {
                    !hideButton &&
                    <Link to="/home/wallet" onClick={()=> Mixpanel.track('Add Funds button was clicked')}>
                        <button className="text-black flex mx-auto block py-1 px-3 bg-white border border-white rounded-[5px]">
                            <p className="m-0 font-xs">Add Funds</p>
                            <img src={add} alt="add fund" className="mt-[2px] ml-[5px]" />
                        </button>
                    </Link>
                }
            </div>
        </div>
    )
}