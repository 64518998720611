import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';
import Select from 'react-select'


export const SelectField = ({
    label, 
    required, 
    floating, 
    options,
    onChange,
    value,
    searchable=false,
    selectMultiple=false,
    labelColor="text-cc-black-4",
    optionHoverColor="",
    optionSelectedColor="",
    backgroundColor, // optional
    inputValueTextColor, // optional
    typingTextColor, // optional
    hideArrowAndLine, // optional
    ...props
}) => {
   const [field, meta] = useField(props);
   const [active, setActive] = useState(false)

    const handleChange = (e) => {
        onChange(e)
    }

    const customStyles = {
        control: (styles) => ({ 
            ...styles, 
            borderWidth: '1px',
            margin:'5px 0 10px',
            padding: '2px 0 2px 5px',
            boxShadow: 'none',
            borderColor: (meta.touched && meta?.error) ? "#FF3804":"#C4C4C4",
            "&:focus": {
                borderColor: '#0046E6',
            },
            "&:hover": {
                borderColor: (meta.touched && meta?.error) ? "#FF3804":'#C4C4C4',
            },
            backgroundColor: backgroundColor || "white",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: inputValueTextColor || "#8A8A8A",
        }),
        input: (provided) => ({
          ...provided,
          color: typingTextColor || "#8A8A8A",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          display: hideArrowAndLine ? "none":"flex", // Hides the dropdown arrow
        }),
        // indicatorSeparator: () => ({
        //   display: "none", // Hides the vertical line next to the arrow
        // }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                ...(
                    !!optionHoverColor ? 
                    {
                        backgroundColor: (
                            isSelected ? (optionSelectedColor||optionHoverColor)
                            : isFocused ? optionHoverColor:""
                        ),
                    }:{}
                )
            };
        }
    };

    return(
        <div className='relative pb-[15px]'>
            {
                !!label &&
                <label 
                    htmlFor={props.name}
                    style={{zIndex: '2'}}
                    className={clsx( 
                        "text-base",
                        (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                        floating ? "absolute left-[16px] transition-all" : "font-semibold",
                        labelColor,
                    )}
                >
                    {label}
                    {
                        required &&
                        <span className='text-cc-red-4'>*</span>
                    }
                </label>
            }
            <div>
                <Select
                    {...props}
                    name={field.name}
                    onBlur={field.onBlur}
                    onFocus={()=>setActive(true)}
                    onChange={handleChange}
                    options={options}
                    placeholder={floating ? "":props.placeholder }
                    styles={customStyles}
                    isSearchable={searchable}
                    isMulti={selectMultiple}
                    value={
                        !value ? null
                        : selectMultiple && Array.isArray(value) ? options?.filter(v => value?.includes(v?.value))
                        : options?.find((v) => v?.value === value)
                    }
                />
            </div>
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[7px]'>{meta.error}</p>
            }
        </div>
    )
}