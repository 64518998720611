import { useEffect, useState } from "react";
import { Col, Row, Modal, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Terms from "pages/Dashboard/Float/components/Terms";
import Back from 'components/shared/back'
import floatDisbursed from "../../../../images/svg/float/float-disbursed.svg";
import { toast } from "react-toastify";
import { FloatService } from "services/float.service";
import { useSelector } from "react-redux";
import { currencyFormatter } from "utils/helpers";
import { DragToUpload } from "components/Form/DragToUpload";
import { FormikProvider, useFormik } from "formik";
import { handleRequestErrors } from "utils/helpers";
import Button from "components/shared/Button";
import ConfirmAction from "components/Modal/ConfirmAction";


function OfferDetails () {
    const [warningType, setWarningType] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showDisbursed, setShowDisbursed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const b2b_data = useSelector(state => state?.loginData?.data)
   
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [uploading, setUploading] = useState(false)
    
 
    let navigate = useNavigate()
    let data = JSON.parse(sessionStorage.getItem('floatOffer'));
    const offerData = data?.approvedFloat;

    const handleAcceptModal = () => {
        if(acceptTerms && !!form.values.agreementFile){
            handleAgreementFile()
        }else{
            toast.error('Please ensure you accept terms and upload agreement consent to continue')
        }
    }

    const acceptAction = () => {
        FloatService.floatAgreement(data?._id)
        .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    acceptFloatOfferAction()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, false, 30000)
            });
    }

    const acceptFloatOfferAction = () => {
        let payload = {
            userId: b2b_data?.user?.id,
            businessId: b2b_data?.user?.businessId,
            approvedLoanAmount: data?.approvedFloat?.approvedAmount,
            approvedLoanDuration: data?.approvedFloat?.loanTenure,
            interestRate: data?.approvedFloat?.monthlyIntrestRate,
            interestType: data?.approvedFloat?.monthlyIntrestType,
            amountType: data?.amountType
        }
        FloatService.acceptFloatOffer(data?._id, payload)
        .then((response) => response.data)
            .then((res) => {
                setShowWarningModal(false)
                if (res.status === 'success') {
                    setShowDisbursed(true)  
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, false, 30000)
            });
    }

    const declineAction = () => {
        FloatService.declineFloatOffer(data?._id)
        .then((response) => response.data)
            .then((res) => {
                setShowWarningModal(false)
                if (res.status === 'success') {
                    toast.success('Float Offer Declined')
                    navigate('/float/status')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, false, 30000)
            });
    }

    useEffect(() => {
        if( acceptTerms ){
           setIsDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptTerms])

    const form = useFormik({
        initialValues: {
            agreementFile: null,
        },
        onSubmit: (values) => {
        }
    })

    const handleAgreementFile = () => {
        setUploading(true)
        let value = new FormData();
        value.append('businessId', b2b_data?.user?.businessId);
        value.append('floatId', data?._id);
        value.append('file', form.values.agreementFile);
        FloatService.uploadAgreementConsent(value)
            .then((response) => response.data)
            .then((res) => {
                setUploading(false)
                if (res.status === 'success') {
                    setWarningType('accept-float');
                    setShowWarningModal(true);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setUploading(false)
                handleRequestErrors(err)
            });
    }

    return (
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <Container>
             <Back to={()=> navigate("/float/status")} />
                <div className="float_request">
                    <h3 className="text-[1.3em] font-medium">{b2b_data?.businessName}</h3>
                    <p>We have successfully reviewed the information. Please go over the details before accepting</p>
                    <div>
                        <Row>
                            <Col sm={8} lg={5}>
                                <div className="float_request-box">
                                    <h4 className="text-center text-[1.2em] font-medium" style={{marginBottom: '20px'}}>Approved Float Request</h4>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Request ID</p>
                                        <p className="text2 !lowercase">{data?.requestCode||"---"}</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Approved Float Amount</p>
                                        <p className="text2">{currencyFormatter(offerData?.approvedAmount)}</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Monthly Interest rate</p>
                                        <p className="text2">{offerData?.monthlyIntrestRate}%</p>
                                    </div>
                                    {/* <div className="float_request-item mb-2.5">
                                        <p className="text1">Monthly Interest amount</p>
                                        <p className="text2">N100,000</p>
                                    </div> */}
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Loan Tenure</p>
                                        <p className="text2">{offerData?.loanTenure} months</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Interest Type</p>
                                        <p className="text2">{offerData?.monthlyIntrestType}</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Total Repayment</p>
                                        <p className="text2">{currencyFormatter(offerData?.totalRepayment)}</p>
                                    </div>
                                </div>
                                <div className='mt-[20px]'>
                                    <div className='float_request-checks'>
                                        <input type="checkbox" checked={acceptTerms} onClick={()=>setShowTerms(true)}/>
                                        <p >Terms and Conditions</p>
                                    </div>
                                    <div className='float_request-checks'>
                                        <input type="checkbox" checked={acceptTerms} onClick={()=>setShowTerms(true)}/>
                                        <p >Recovery Mandate</p>
                                    </div>
                                    <div className='float_request-checks'>
                                        <input type="checkbox" checked={acceptTerms} onClick={()=>setShowTerms(true)}/>
                                        <p >Penalties and Fees</p>
                                    </div>
                                    <div>
                                        <p className="mt-10 mb-6">Download a copy of our PDF agreement Consent, sign, upload and submit</p>
                                        <FormikProvider value={form}>
                                            <form onSubmit={form.handleSubmit}>
                                                <div className="max-w-[350px]">
                                                    <div>
                                                        {
                                                            !!form.values.agreementFile && 
                                                            <p>{form.values.agreementFile.name}</p>
                                                        }
                                                        <DragToUpload
                                                            name="agreementFile"
                                                            fileType="pdf"
                                                            message="Signed copy of agreement contract"
                                                            onChange={(file)=>form.setFieldValue("agreementFile", file)}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </FormikProvider>
                                    </div>
                                    <div className="grid grid-cols-2 gap-[20px]">
                                        <Button
                                            isValid={true}
                                            type="button"
                                            text="Decline"
                                            className="whitespace-nowrap py-2 !bg-[#D92D20]"
                                            onClick={()=>{setWarningType('decline-float'); setShowWarningModal(true);}}
                                        />
                                        <Button
                                            isValid={!isDisabled && !!form.values.agreementFile}
                                            type="button"
                                            text="Accept"
                                            className="whitespace-nowrap py-2 !bg-cc-primary"
                                            onClick={handleAcceptModal} 
                                            isLoading={uploading}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={7}>
                                <div className="float_request-box">
                                    <h5 style={{marginBottom: '20px'}} className="text-[1.2em] font-medium">Repayment Breakdown</h5>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Principal</th>
                                                <th>Interest</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                offerData?.repaymentBreakdown?.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td>{currencyFormatter(item?.principal)}</td>
                                                        <td>{currencyFormatter(item?.interest)}</td>
                                                        <td>{currencyFormatter(item?.totalRepayment)}</td>
                                                    </tr>
                                                )) 
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <ConfirmAction
                show={showWarningModal}
                onCancel={()=>setShowWarningModal(false)}
                title={
                    warningType==='accept-float' ? "Accept Float Offer"
                    : "Decline Float Offer"
                }
                content={`Are you sure you want to ${warningType==='accept-float' ? "accept" : "decline"} this offer? This action cannot be undone.`}
                actionText={warningType==='accept-float' ? "Accept" : "Decline"}
                type={warningType==='accept-float' ? "primary" : "delete"}
                onAction={()=> {
                    warningType==='accept-float' ? acceptAction() : declineAction()
                }}
            />
            <Modal show={showDisbursed} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <img src={floatDisbursed} alt='' />
                    <h5 className="text-[1.3em]">Float Accepted</h5>
                    <p style={{color:"#667085"}} className="my-2.5">Congratulations, your Float request will be disbursed shortly to your account</p>
                    <Link to="/float/status"><button className="float_modal-action">Go Back</button></Link>
                </div>
            </Modal>
            <Terms 
                show={showTerms} 
                handleClose={()=>setShowTerms(false)} 
                accept={()=>{setAcceptTerms(true);setShowTerms(false);}} 
                decline={()=>{setAcceptTerms(false);setShowTerms(false);}} 
                data={data} 
            />
        </div>
    )
}

export default OfferDetails;